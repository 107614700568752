.about-us {
    padding: 20px;
    text-align: center;
    background: #f9f9f9;
    color: #333;
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-us h1,
  .about-us h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-us p {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 10px 0;
  }

  .carousel-container {
    max-width: 600px; /* Set your desired maximum width */
    margin: 0 auto; /* Center the carousel */
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
    border-radius: 25px;
  }
  