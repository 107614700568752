.heading-with-pins {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.pin-image {
  width: 30px; /* Adjust size as needed */
  height: auto;
  margin: 0 10px; /* Space between the pin and the text */
}



/* Container Styles */


.containerpolicy {
  max-width: 800px; /* Reduced width for less horizontal spread */
  margin: 0 auto;
  padding: 20px; /* Reduced padding */
  background: #f8f8f8; /* Lighter grey background for a softer look */
  border-radius: 18px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header Styles */
.header {
  text-align: center;
  margin-bottom: 20px; /* Reduced margin */
}

.header h1 {
  font-size: 2rem; /* Slightly smaller font size */
  color: #333; /* Darker text for better readability */
  margin-bottom: 10px; /* Reduced margin */
}

/* Section Styles */
.policy-section {
  margin-bottom: 30px; /* Reduced margin */
}

.policy-section h2 {
  font-size: 1.75rem; /* Slightly smaller font size */
  color: #222; /* Darker text for section headings */
  border-bottom: 2px solid #bbb; /* Light grey border */
  padding-bottom: 5px; /* Reduced padding */
  margin-bottom: 15px; /* Reduced margin */
}

/* Accordion Styles */
.accordion-item {
  border: 1px solid #ccc; /* Lighter border for a subtle effect */
  border-radius: 8px;
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #e0e0e0; /* Subtle background for the header */
  color: #333; /* Dark text for contrast */
  border-radius: 8px;
}

.accordion-button {
  color: #333;
  font-size: 1rem; /* Slightly smaller font size */
  padding: 10px; /* Reduced padding */
  background-color: transparent;
  border: none;
  text-align: left;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}

.accordion-button:not(.collapsed) {
  background-color: #ccc; /* Slightly darker background when expanded */
}

.accordion-button::after {
  /* content: "+"; */
  font-size: 1.25rem;
  float: right;
  transition: transform 0.3s ease;
  margin-left: 10px; /* Space between text and icon */
}

.accordion-body {
  padding: 15px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly smaller font size */
  line-height: 1.6;
  border-top: 1px solid #ddd; /* Light grey border */
  background-color: #fff; /* White background for body content */
}
