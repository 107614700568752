.blog-post-page {
    max-width: 900px; /* Increased max-width for more spacious content */
    margin: 50px auto; /* Increased margin for more spacing from top and bottom */
    padding: 30px; /* Increased padding for more internal space */
    background-color: #ffffff; /* Clean white background */
    border: 1px solid #e0e0e0; /* Soft border */
    border-radius: 15px; /* More rounded corners for a modern look */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Deeper shadow for a floating effect */
    text-align: center; /* Center align text within the container */
  }

  .blog-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.blog-actions a {
    color: #555;
    transition: color 0.3s ease;
    cursor: pointer;
}

.blog-actions a:hover {
    color: #000;
}

  
  h1 {
    font-size: 2.5rem; /* Larger font size for emphasis */
    margin-bottom: 30px; /* Increased margin for spacing */
    color: #333; /* Dark color for readability */
    font-family: 'Arial', sans-serif; /* Clean, modern font */
    font-weight: 700; /* Bolder font weight */
    text-transform: uppercase; /* Uppercase for a stronger presence */
    letter-spacing: 1px; /* Slightly increased letter spacing */
    background: linear-gradient(45deg, #ff6f61, #ff8e53); /* Gradient background */
    -webkit-background-clip: text; /* Clip background to text */
    -webkit-text-fill-color: transparent; /* Make text fill color transparent */
    padding: 10px 0; /* Padding for more emphasis */
    border-bottom: 3px solid #ff8e53; /* Bottom border for additional emphasis */
  }
  
  .editor-container {
    margin-bottom: 40px; /* More space below the editor */
  }
  
  .image-upload-container1 {
    margin-bottom: 40px; /* Increased margin for more spacing */
    display: flex;
    align-items: center;
    justify-content: center; /* Center align content horizontally */
    gap: 35px; /* More gap between label and input */
  }
  
  .image-upload-container label {
    font-weight: 600; /* Bolder font for the label */
    font-size: 1.1rem; /* Slightly larger font size */
    color: #555; /* Lighter color for a softer look */
  }
  
  .image-upload-container input[type="file"] {
    padding: 10px; /* Increased padding for better usability */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ccc; /* Lighter border color */
    background-color: #f9f9f9; /* Light background color */
    font-size: 1rem; /* Consistent font size */
  }
  
  .editor-output-container {
    margin-bottom: 40px; /* Increased margin for spacing */
  }
  
  .editor-output-container h2 {
    font-size: 2rem; /* Larger subheading size for better hierarchy */
    color: #4CAF50; /* Vibrant color for the subheading */
    margin-bottom: 20px; /* More space below the heading */
    font-family: 'Arial', sans-serif; /* Consistent font style */
    font-weight: 700; /* Bolder font weight */
    text-transform: capitalize; /* Capitalize for a stronger presence */
    letter-spacing: 0.5px; /* Slightly increased letter spacing */
    border-bottom: 2px solid #4CAF50; /* Bottom border for additional emphasis */
    padding-bottom: 10px; /* Padding for more emphasis */
  }
  
  .editor-output-container div {
    padding: 15px; /* Increased padding for more internal space */
    border: 1px solid #ddd; /* Soft border color */
    border-radius: 10px; /* More rounded corners */
    background-color: #fafafa; /* Light background for better contrast */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the output area */
  }
  
  .submit-button-container {
    text-align: center;
  }
  
  .submit-button {
    padding: 15px 30px; /* Larger padding for better touch targets */
    background-color: #4CAF50; /* Main color */
    color: #fff;
    border: none;
    border-radius: 20px; /* More rounded corners */
    cursor: pointer;
    font-size: 1.2rem; /* Larger font size for readability */
    transition: background-color 0.5s ease, transform 0.5s ease; /* Smooth transition for hover effect */
  }
  
  .submit-button:hover {
    background-color: #45a049; /* Slightly darker shade for hover effect */
    transform: translateY(-2px); /* Subtle lift effect on hover */
  }
  