/* CartPage.css */
.cart-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .cart-item {
    background-color: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 20px;
    position: relative; /* Position relative for absolute positioning */
  }
  
  .product-info {
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .remove-btn-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .remove-btn {
    background-color: transparent;
    border: none;
    color: #999;
    font-size: 20px;
    cursor: pointer;
  }
  
  .remove-btn:hover {
    color: #f00; /* Change color on hover */
  }
  
  .image-container {
    width: 100%;
    height: 200px; /* Adjust as needed */
    overflow: hidden;
    border-radius: 10px;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .product-image:hover {
    transform: scale(1.05);
  }
  
  .item-details {
    margin-top: 15px;
  }
  
  .product-name {
    font-size: 1.2rem;
    /* margin-bottom: 10px; */
  }
  
  .quantity,
  .total-price,
  .booking-date {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  .parent-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Set the height of the container to the full viewport height */
  }
  
  .content {
    text-align: center; /* Center text horizontally */
  }

  /* Add this CSS to CartPage.css */
  .checkout-box {
    background-color: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .checkout-box .checkout-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkout-box .checkout-summary button {
    background-color: #4caf50; /* Change button color */
    color: #ffffff; /* Text color */
    border: none;
    padding: 15px 20px;
    border-radius: 5px;
    
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-box .checkout-summary button:hover {
    background-color: #45a049; /* Darker color on hover */
  }

.booking-details {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quantity,
.total-price,
.booking-date {
  margin: 8px 0;
  font-size: 16px;
}

.quantity strong,
.total-price strong,
.booking-date strong {
  color: #333;
}

.booking-date {
  border-top: none;
  padding-top: none;
}

.booking-date strong {
  display: block;
}

  