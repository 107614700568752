.footer {
    background-color: #88670d;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-logo {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .footer .row {
    display: flex;
    justify-content: space-between;
  }
  
  .footer h5 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #000000;
  }
  
  .footer p, .footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .footer ul li {
    margin-bottom: 5px;
  }
  
  .footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  
  .social-icons a {
    color: #fff;
    margin-right: 10px;
    font-size: 24px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #ccc;
  }
  .footer a {
    color: inherit; /* Inherit color from parent elements */
    text-decoration: none; /* Remove underline */
  }
  
  .footer a:hover {
    color: inherit; /* Ensure color stays the same on hover */
  }  