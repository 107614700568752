.blog-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    background-color: #fff;
    max-width: 600px;
    margin: 10px auto;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-header {
    margin-bottom: 15px;
}

.blog-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.blog-author {
    font-size: 14px;
    color: #888;
    margin-top: 5px;
}

.blog-description {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
}

@media (max-width: 768px) {
    .blog-card {
        padding: 15px;
    }
    
    .blog-title {
        font-size: 20px;
    }

    .blog-description {
        max-height: 60px;
    }
}
