.signup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9; /* Light gray background */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 320px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    margin: 30px auto 0;
  }

  .signup-page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
  }

  .signup-footer {
    position: sticky; /* add this */
  bottom: 0; /* add this */
  margin-top: auto;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .signup-header {
    color: #2ecc71; /* Green header color */
  }
  
  .signup-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .signup-submit {
    background-color: #2ecc71; /* Green submit button color */
    color: #fff;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
  }
  
  .signup-text {
    color: #555; /* Dark gray text color */
  }
  
  .signup-link {
    color: #3498db; /* Blue link color */
    text-decoration: none;
  }
  
  .signup-link:hover {
    text-decoration: underline;
  }
  