@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container1 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.card1 {
  height: 570px;
  width: 320px;
  background-color: #fff;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.overlay .fa-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #fff;
}
.overlay img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.5s;
}
.d-none {
  display: none;
}
.fa-close {
  cursor: pointer;
  transition: all 0.5s;
}
.black {
  background-color: #000 !important;
  color: #fff !important;
}
.font_icons {
  color: #fff !important;
}
.top_part {
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  color: #505050;
  cursor: pointer;
}
.icons .fa-heart-o {
  margin-right: 10px;
}
.icons .fa-heart {
  margin-right: 10px;
  color: red;
}
.fa-moon-o {
  margin-right: 5px;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
}
.circle span {
  display: flex;
  height: 120px;
  width: 120px;
  border: 3px solid red;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
}
.circle span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.5s;
}
.card1 p {
  padding: 0 20px;
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
}
.card1 hr {
  margin-top: 15px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.button button {
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  background-color: #6d5afa;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.5s;
}
.button button:hover {
  background-color: blue;
}
/* .social {
  position: relative;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
} */

.social {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px; /* Add padding to align icons properly */
}

.social a {
  display: inline-block; /* Ensure the icons are block-level elements */
}
.social .fa-facebook {
  margin-top: auto; /* Align icons to the bottom */
  margin-bottom: 10px; /* Add margin for spacing between icons and bottom */
  /* position: absolute;
  left: 20px; */
  cursor: pointer;
  transition: all 0.5s;
  bottom:0px;
  
}
.card1:hover .fa-facebook {
  transform: translateY(-290px);
  /* bottom: 250px; */
}
.fa-instagram {
  margin-top: auto; /* Align icons to the bottom */
  margin-bottom: 10px; /* Add margin for spacing between icons and bottom */
  /* position: absolute;
  left: 153px; */
  bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
}
.card1:hover .fa-instagram {
  transform: translateY(-290px);
  /* bottom: 250px; */
}
.fa-whatsapp {
  margin-top: auto; /* Align icons to the bottom */
  margin-bottom: 10px; /* Add margin for spacing between icons and bottom */
  /* position: absolute;
  left: 288px; */
  bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
}
.card1:hover .fa-whatsapp {
  transform: translateY(-290px);
  /* bottom: 250px; */
}

.fbox {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 10px;
}